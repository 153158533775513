import {Service} from "@/services/Service";

export class CacheService extends Service{

    private cache = new Map<string, any>();

    protected constructor() {
        super();
    }

    public get(key: string): any {
        return this.cache.get(key);
    }

    public set(key: string, value: any): any {
        this.cache.set(key, value);
    }

}
