<template>
  <div>
    <label :for="name" class="form-label fw-bold w-100" ref="labelRef" v-if="showLabel">
      <span v-if="label" class="pe-3">{{ label }}</span>
      <slot>
        <span v-if="isRequired" class="text-danger">*</span>
      </slot>
    </label>

    <div class="form-control" :class="{ 'is-invalid': errors[name] }">
      <textarea ref="editor"></textarea>
    </div>

    <dp-error-message class="invalid-feedback d-block" :name="name"></dp-error-message>
  </div>
</template>

<script>
import {computed, getCurrentInstance, watch} from "vue";
import {useField} from 'vee-validate';
import {generateUUIDv4} from "@/helpers/functions/string";
import {Config} from "@/models/facade/Config";

export default {
  name: "InputTextEditor",
  data() {
    return {
      showLabel: true,
      tinyMCEEditor: null
    }
  },
  computed: {
    Config() {
      return Config.getInstance();
    },
    getForcedRootBlock() {
      return this.wrapContentTag === '' ? 'div' : this.wrapContentTag;
    },
    getForcedRootBlockAttrs() {
      if (this.wrapContentTag === '') {
        return {
          'data-dp-uc-wrapper': 'inline'
        };
      }
      return {};
    }
  },
  mounted() {
    this.initTinyMCE();
    this.showLabel = this.labelHasContent();
  },
  methods: {
    labelHasContent() {
      if (this.label) {
        return true;
      }
      return Array.from(this.$refs.labelRef.childNodes).filter(node => node.nodeType === Node.ELEMENT_NODE).length;
    },
    initTinyMCE() {
      if (window.tinymce) {
        window.tinymce.init({
          target: this.$refs.editor,
          setup: (editor) => {
            editor.on('blur', () => {
              this.$emit('blur');
            });
            editor.on('init', () => {
              editor.on('change input undo redo', () => {
                this.$emit('update:modelValue', editor.getContent());
              });
              editor.setContent(this.value);
            });
            this.tinyMCEEditor = editor;
          },
          menubar: false,
          content_css: '/css/editor/sma.css',
          name: this.name,
          height: this.height,
          forced_root_block: this.getForcedRootBlock,
          forced_root_block_attrs: this.getForcedRootBlockAttrs,
          toolbar: this.toolbar,
          plugins: this.plugins,
          newline_behavior: this.newLineBehavior,
          contextmenu: false,
          default_link_target: '_blank',
          language_url: '/js/tinymce/' + this.locale + '.js',
          language: this.locale,
          convert_urls: false,
          formats: {
            smaFsSm: {selector: 'p,h2,h3,h4', classes: 'sma-fs-sm'},
            smaFsDefault: {selector: 'p,h2,h3,h4', classes: 'sma-fs-default'},
            smaFsLg: {selector: 'p,h2,h3,h4', classes: 'sma-fs-lg'},
            smaFsXl: {selector: 'p,h2,h3,h4', classes: 'sma-fs-xl'},
            smaFsXxl: {selector: 'p,h2,h3,h4', classes: 'sma-fs-xxl'},
            bold: {inline: 'span', classes: 'fw-700'},
            italic: {inline: 'span', classes: 'fst-italic'},
            underline: {inline: 'span', classes: 'text-decoration-underline'},
            strikethrough: {inline: 'span', classes: 'text-decoration-line-through'}
          },
          style_formats: [
            {title: this.$t('headingTwo'), format: 'h2'},
            {title: this.$t('headingThree'), format: 'h3'},
            {title: this.$t('headingFour'), format: 'h4'},
            {title: 'Blockquote', format: 'blockquote'},
            {title: this.$t('small'), format: 'smaFsSm'},
            {title: this.$t('default'), format: 'smaFsDefault'},
            {title: this.$t('large'), format: 'smaFsLg'},
            {title: this.$t('extraLarge'), format: 'smaFsXl'},
            {title: this.$t('extraExtraLarge'), format: 'smaFsXxl'},
          ]
        });
        watch(
          () => this.modelValue, (value, oldValue) => {
            this.value = value;
            if (this.tinyMCEEditor && value !== oldValue && value !== this.tinyMCEEditor.getContent()) {
              this.tinyMCEEditor.setContent(value ?? '');
            }
          }
        );
      }
    }
  },
  beforeUnmount() {
    if (this.tinyMCEEditor) {
      this.tinyMCEEditor.remove();
    }
  },
  emits: ['update:modelValue', 'blur'],
  props: {
    modelValue: {
      required: false,
      default: '',
    },
    mode: {
      type: String,
      required: false,
      default: 'editor',
      validate(value) {
        return value === 'editor' || value === 'bare' || value === 'basic';
      }
    },
    errors: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 300,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      required: false,
      default() {
        return generateUUIDv4('input-text-editor');
      }
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    language: {
      type: String,
      required: false,
    },
    wrapContentTag: {
      type: String,
      required: false,
      default: 'p',
      description: 'The tag to wrap around the returned html content.  p is default, other values can be div, ' +
        'or an empty string.  In case of an empty string, the content will be wrapped in a div tag, with an extra attribute data-dp-uc-wrapper="inline"'
    }
  },
  setup(props) {
    const vm = getCurrentInstance();
    const {value} = useField(props.name, props.rules, {initialValue: props.modelValue ?? ''});

    const locale = computed(() => {
      switch (vm.ctx.$i18n.locale) {
        case 'en': {
          return 'en';
        }
        case 'fr': {
          return 'fr_FR';
        }
        case 'nl': {
          return 'nl';
        }
        default:
          return this.$i18n.locale;
      }
    });
    const plugins = computed(() => {
      switch (props.mode) {
        case 'bare':
          return ['wordcount'];
        case 'basic':
          return ['wordcount', 'link'];
        default:
          return ['advlist',
            'autolink',
            'lists',
            'link',
            'charmap',
            'preview',
            'searchreplace',
            'visualblocks',
            'wordcount',
            'media',
            'code',
            'link'];
      }
    });
    const newLineBehavior = computed(() => {
      switch (props.mode) {
        case 'bare':
        case 'basic':
          return 'linebreak';
        default:
          return 'default';
      }
    });
    const isRequired = computed(() => {
      return props.rules && props.rules.includes("required");
    });
    const toolbar = computed(() => {
      switch (props.mode) {
        case 'bare':
          return 'undo redo | pastetext';
        case 'basic':
          return 'undo redo | bold italic underline | link unlink | pastetext';
        default:
          return 'undo redo | styles | bold italic underline Superscript Subscript | removeformat | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist | link unlink | pastetext | media code';
      }
    });

    return {
      value,
      locale,
      plugins,
      isRequired,
      toolbar,
      newLineBehavior
    };
  },
};
</script>

<style scoped lang="scss">
.form-control {
  padding: unset;
  border-color: transparent;
  border-radius: inherit;

  &.is-invalid {
    padding: inherit;
    border-color: var(--dp-danger);
  }
}
</style>
