
import {defineComponent} from 'vue'
import {mapActions as mapActionsP, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import InputNumber from "@/components/UI/InputNumber.vue";
import InputText from "@/components/UI/InputText.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import SelectBrands from "@/components/layouts/catalog/SelectBrands.vue";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {CatalogProductsCriteriaModel} from "@/models/criteria/CatalogProductsCriteriaModel";
import {CustomerContext} from "@/context/CustomerContext";
import SelectCategories from "@/components/layouts/catalog/SelectCategories.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import SelectTrademarks from "@/components/layouts/catalog/SelectTrademarks.vue";
import InputRadioBoolean from "@/components/UI/InputRadioBoolean.vue";

export default defineComponent({
  name: "CatalogProductsOverviewCriteria",
  emits: ["reloadedContent", "submit"],
  components: {
    InputRadioBoolean,
    SelectTrademarks,
    InputCheckboxBoolean, SelectCategories,
    SelectBrands,
    BaseSpinner, AlertError2, InputText, InputNumber
  },
  data() {
    return {
      catalogCustomerCode: CustomerContext.getCustomerCode(),

      criteriaUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapState(useCustomerStore, ["getCriteria", "getPaging", "getPimState"]),
    productsCriteria(): CatalogProductsCriteriaModel {
      return this.getCriteria.catalog_products;
    },
    productsCriteriaIsChanged(): boolean {
      return this.getPimState.catalog_products_criteria_is_changed;
    },
    productsPaging(): PimProductsPagingModel {
      return this.getPaging.pim_products;
    },
  },
  methods: {
    ...mapActionsP(useCustomerStore, ["clearCatalogProductsCriteria"]),
    ...mapActionsP(useCustomerStore, ["searchAllBrandsCustomer", "searchAllTrademarksCustomer", "searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer"]),
   clearCriteria(): void {
      this.clearCatalogProductsCriteria();

      this.onCriteriaChanged();
    },
    onCriteriaChanged(): void {
      this.getPimState.catalog_products_criteria_is_changed = true;
    },
    setLaboCode(laboCode?: string | null): void {
      this.productsCriteria.filter_labo_code = laboCode;
      this.onCriteriaChanged();
    },
    setTrademarkCode(trademarkCode?: string | null): void {
      this.productsCriteria.filter_trademark_code = trademarkCode;
      this.onCriteriaChanged();
    },
    setCategoryCodeDpManaged(categoryCode?: string | null): void {
      this.productsCriteria.filter_category_code = categoryCode;
      this.onCriteriaChanged();
    },
    async reloadContent(): Promise<void> {
      this.criteriaUI
        .setNotReady()
        .clearError();

      try {
        await Promise.all([
           this.searchAllBrandsCustomer(),
           this.searchAllTrademarksCustomer(),
           this.searchAllCategoriesCustomer(),
           this.searchProductCategoriesTreeCustomer()
         ]);

        this.getPimState.catalog_products_criteria_is_changed = false;
        this.$emit('reloadedContent');
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.criteriaUI.setReady();
      }
    },
    submitData(): void {
      if (this.getPimState.catalog_products_criteria_is_changed) {
        this.productsPaging.number = 1;
      }

      this.getPimState.catalog_products_criteria_is_changed = false;
      this.$emit('submit');
    },
  }
})
