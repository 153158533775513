import {ProductCategorizationAPIApi} from "@/api/pharma-cpc-product-mgmt";
import {RestService} from "@/services/rest/RestService";
import {
    CustomerCategorizationActionOverviewResultsRestDto,
    CustomerCategorizationActionRestDto,
    ProductCategoriesForProductRestDto
} from "@/api/pharma-cpc-product-mgmt/models";
import {CustomerContext} from "@/context/CustomerContext";
import {ProductCategoryAPIApi} from "@/api/pharma-cpc-product";
import {ProductCategoryRestDto} from "@/api/pharma-cpc-product/models";

export class ProductCategorizationRestService extends RestService {
    private productCategorizationApi: ProductCategorizationAPIApi;
    private productCategoryApi: ProductCategoryAPIApi;

    protected constructor() {
        super();

        this.productCategorizationApi = new ProductCategorizationAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
        this.productCategoryApi = new ProductCategoryAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcProductUrl);
    }
    public async addProductCategorizationAction(body: CustomerCategorizationActionRestDto, productCode: string): Promise<void> {
        await this.productCategorizationApi.addProductCategorizationAction(body, CustomerContext.getCustomerCode(), productCode);
    }

    public async deleteProductCategorizationAction(productCode: string, actionCode: string, categoryCode: string): Promise<void> {
        await this.productCategorizationApi.deleteProductCategorizationAction(CustomerContext.getCustomerCode(), productCode, actionCode, categoryCode);
    }

    public async findCustomerCategorizationActions(productCode: string): Promise<CustomerCategorizationActionOverviewResultsRestDto> {
        const response =
            await this.productCategorizationApi.findCustomerCategorizationActions(CustomerContext.getCustomerCode(), productCode);

        return response.data;
    }

    public async findMasterProductCategories(productCode: string): Promise<ProductCategoriesForProductRestDto> {
        const response = await this.productCategorizationApi.findMasterProductCategories(productCode);

        return response.data;
    }

    public async findProductCategoriesTree(): Promise<ProductCategoryRestDto> {
        // TODO: cache results
        const response =
            await this.productCategoryApi.findProductCategoriesTree(CustomerContext.getCustomerCode());

        return response.data;

    }
}
